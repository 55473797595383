<template>
	<div class="panebox">

		<div class="pane">
			<div class="pane_tit">巡防任务</div>
			<div class="pane_cont">
				<el-row :gutter="20">
					<!-- <el-col :span="8">
						<div>巡防周期：<span>{{ getOptionName(taskInfo.period, periodOptions) }}</span></div>
					</el-col> -->
					<el-col :span="8">
						<div>负责人：<span>{{ taskInfo.gridmanname }}</span></div>
					</el-col>
					<el-col :span="24">
						<div>巡防地址：<span>{{ taskInfo.housename }}</span></div>
					</el-col>
					<el-col :span="24">
						<div id="sixtyrentalProblemMap" style="height: 200px; width: 98%; margin: 10px auto 20px;"></div>
					</el-col>
				</el-row>
			</div>
		</div>

		<div class="pane">
			<div class="pane_tit">处理信息</div>
			<div class="pane_cont">
				<div class="flow">
					<div class="flowitem">
						<!-- <div class="icon"></div> -->
						<div class="desc">
							<div style="align-items: center;">
								<div class="filed">巡防时间：</div>
								<div>{{ taskDealInfo.create_time }}</div>
								<div v-if="taskDealInfo.gridman_name">，{{ taskDealInfo.gridman_name }}</div>
								<div v-if="taskDealInfo.problem == 1">，<span style="color: #ee9934 ">发现问题</span></div>
								<i @click="xianshi(taskDealInfo.problem_xy)" style="margin-left: 10px;cursor: pointer;" class="el-icon-location-information"></i>
							</div>
							<div v-if="taskDealInfo.problem_description">
								<div class="filed">问题描述：</div>
								<div style="color: #dd4a68;">{{ taskDealInfo.problem_description }}</div>
							</div>
							<div
								v-if="taskDealInfo.problem_attachment_path && taskDealInfo.problem_attachment_path.length">
								<div class="filed">相关图片：</div>
								<div class="imgs">
									<el-image style="height: 90px; width: auto; margin-right: 10px;"
										v-for="(imgurl,index) in taskDealInfo.problem_attachment_path" :key="index"
										:src="imgurl" :preview-src-list="taskDealInfo.problem_attachment_path">
									</el-image>
								</div>
							</div>

							<!-- 处理 -->
							<div v-if="taskDealInfo.deal_manname">
								<div class="filed">处理人：</div>
								<div>{{ taskDealInfo.deal_manname }}</div>
							</div>
							<div v-if="taskDealInfo.deal_description">
								<div class="filed">处理描述：</div>
								<div style="color: #dd4a68;">{{ taskDealInfo.deal_description }}</div>
							</div>
							<div v-if="taskDealInfo.deal_attachment_path && taskDealInfo.deal_attachment_path.length">
								<div class="filed">相关图片：</div>
								<div class="imgs">
									<!-- <img v-for="(imgurl,index) in taskDealInfo.deal_attachment_path" :key="index" :src="imgurl" alt="">   -->
									<el-image style="height: 90px; width: auto; margin-right: 10px;"
										v-for="(imgurl,index) in taskDealInfo.deal_attachment_path" :key="index"
										:src="imgurl" :preview-src-list="taskDealInfo.deal_attachment_path">
									</el-image>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<el-dialog append-to-body title="巡防地点" :close-on-click-modal="false" :visible.sync="detailVisible" width="1000px">
			<div id="sixtyrentalProblemMap2" style="height: 400px; width: 98%; margin: 10px auto 20px;"></div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		lazyAMapApiLoaderInstance
	} from 'vue-amap';
	import {
		problemDetail
	} from '@/api/sixtyPatrol'

	export default {
		name: "detail",
		components: {},
		props: {
			taskId: {
				type: Number,
				default: null
			}
		},
		data() {
			return {
				// periodOptions,
				taskInfo: {},
				taskDealInfo: {},
				detailVisible: false,
			}
		},
		watch: {
			taskId: {
				handler(newVal, oldVal) {
					if (newVal) {
						this.getInfo();
					}
				},
				immediate: true
			}
		},
		created() {},
		mounted() {},
		computed: {},
		methods: {
			xianshi(a){
				let that = this;
				let center = a.split(',');
				that.detailVisible = true;
				lazyAMapApiLoaderInstance.load().then(() => {
					let map = new AMap.Map('sixtyrentalProblemMap2', {
						center,
						zoom: 20,
					})
				
					let marker = new AMap.Marker({
						position: center,
						offset: new AMap.Pixel(-13, -30)
					});
					marker.setMap(map);
				})
			},
			//加载地图
			getmap(center) {
				lazyAMapApiLoaderInstance.load().then(() => {
					let map = new AMap.Map('sixtyrentalProblemMap', {
						center,
						zoom: 20,
					})

					let marker = new AMap.Marker({
						position: center,
						offset: new AMap.Pixel(-13, -30)
					});
					marker.setMap(map);
				})
			},
			//获取任务详情
			getInfo() {
				problemDetail({
					id: this.taskId
				}).then(res => {
					if (res.result == 200) {
						this.taskInfo = res.detail.task;
						this.taskDealInfo = res.detail.log;
						this.getmap(this.taskInfo.jobxy.split(','))
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			getOptionName(value, list) {
				if (value === "" || value == undefined) return "";
				const item = list.find((item) => item.value == value);
				return item ? item.label : "";
			}
		}
	}
</script>

<style lang="scss" scoped>
	.pane {
		margin-bottom: 30px;
		border: 1px solid #ddd;
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

		.pane_tit {
			padding: 10px 20px;
			border-bottom: 1px solid #ddd;
			background: #f9f9f9;
			font-size: 16px;
			color: #333;
		}

		.pane_cont {
			padding: 20px;
			background: #fff;

			.el-row {
				>div {
					margin-bottom: 14px;
					font-weight: bold;
					color: #333;

					span {
						font-weight: normal;
						color: #666;
					}
				}
			}

			.flowitem {
				position: relative;

				.icon {
					position: absolute;
					height: 100%;
					background: #e4e7ed;
					width: 1px;
					top: 15px;

					&::before {
						position: absolute;
						content: '';
						left: 0;
						top: 0;
						transform: translate(-50%, -50%);
						width: 15px;
						height: 15px;
						background: #e4e7ed;
						border-radius: 50%;
					}
				}

				&:last-child {
					.icon {
						height: 0;
					}
				}

				.desc {
					padding: 5px 30px 40px;
					min-height: 40px;
					line-height: 1.6em;

					>div {
						display: flex;
						margin-bottom: 10px;

						.filed {
							width: 120px;
							text-align: right;
						}
					}

					.imgs {
						display: flex;
						margin-bottom: 20px;
						/* img{
              height: 90px;
              width: auto;
              margin-right: 10px;
            } */
					}
				}
			}
		}
	}
</style>